<template>
  <div>
    <b-card class="open-ticket">
      <b-row>
        <b-col cols="12" lg="10">
          <span
            >If you wish to get help repairing any warning or danger level
            issues please contact support. Charges may apply if you do not pay
            or receive free premium hours from us.</span
          >
        </b-col>
        <b-col cols="12" lg="2" class="d-flex justify-content-end">
          <b-button variant="primary" @click="renderedUrl">
            <span>Open Ticket</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="card-height">
      <div class="d-flex justify-content-start">
        <h1>Overview</h1>
      </div>
      <b-breadcrumb class="pl-0">
        <b-breadcrumb-item :to="{ name: 'app-scoutsuite' }">
          Scans
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          {{
            securityanalysisData.securityanalysisProjectName
              .charAt(0)
              .toUpperCase() +
            securityanalysisData.securityanalysisProjectName.slice(1)
          }}
        </b-breadcrumb-item>
      </b-breadcrumb>
      <hr />

      <div>
        <loading
          :active.sync="loading"
          :can-cancel="true"
          :is-full-page="false"
          color="#4285f4"
          loader="dots"
        />
        <b-table
          id="scoutsuite"
          v-show="!loading"
          :items="tableItems"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          empty-text="No matching records found"
          responsive
        >
          <template #head()="data">
            <span v-if="data.label == 'Services'">
              {{ data.label }}
            </span>
            <span v-else class="d-flex justify-content-center">{{
              data.label
            }}</span>
          </template>

          <template #cell(services)="data">
            <feather-icon
              v-if="
                data.item.max_level == 'warning' &&
                (data.item.findings !== 0 || data.item.checks !== 0)
              "
              icon="InfoIcon"
              size="16"
              class="feather-warning feather"
            />
            <feather-icon
              v-if="
                data.item.max_level == 'danger' &&
                (data.item.findings !== 0 || data.item.checks !== 0)
              "
              icon="InfoIcon"
              size="16"
              class="feather-danger feather"
            />
            <feather-icon
              v-if="data.item.findings == 0 && data.item.checks == 0"
              icon="CircleIcon"
              size="16"
              class="feather-normal feather"
            />
            {{ data.item.title }}
          </template>

          <template #cell(resources)="data">
            <span class="d-flex justify-content-center">
              {{ data.item.resources }}
            </span>
          </template>

          <template #cell(rules)="data">
            <span class="d-flex justify-content-center">
              {{ data.item.rules }}
            </span>
          </template>

          <template #cell(findings)="data">
            <span class="d-flex justify-content-center">
              {{ data.item.findings }}
            </span>
          </template>

          <template #cell(checks)="data">
            <span class="d-flex justify-content-center">
              {{ data.item.checks }}
            </span>
          </template>

          <template #cell(actions)="data">
            <span class="d-flex justify-content-center">
              <b-button
                variant="primary"
                class=""
                @click="viewDetailServices(data.item)"
                size="sm"
              >
                <span>View Details</span>
              </b-button>
            </span>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BTable,
  BFormCheckbox,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import axios from "@axios";
import router from "@/router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import { ref } from "@vue/composition-api";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BTable,
    BFormCheckbox,
    Loading,
    BBreadcrumb,
    BBreadcrumbItem,
  },
  data() {
    return {
      tableColumns: [
        { key: "services" },
        { key: "resources" },
        { key: "rules" },
        { key: "findings" },
        { key: "checks" },
        { key: "actions", tdClass: "action-class" },
      ],
      sortBy: "",
      isSortDirDesc: true,
      securityanalysisData: JSON.parse(
        localStorage.getItem("securityanalysisData")
      ),
    };
  },
  beforeMount() {
    this.getTableData();
  },
  watch: {
    "$store.state.app.userData.default_region_id": {
      handler() {
        router.push({ name: "app-scoutsuite" });
      },
    },
  },
  methods: {
    viewDetailServices(item) {
      this.$router.push(
        "/apps/security-scan/" +
          router.currentRoute.params.id +
          "/" +
          item.services
      );
    },
    renderedUrl() {
      this.$router.push({ name: "apps-support-cases-open-ticket" });
    },
  },
  setup() {
    let loading = ref(false);
    let tableItems = ref([]);
    let scoutsuiteResult = ref([]);
    const toast = useToast();

    const getTableData = () => {
      loading.value = true;
      let securityAnalysisId = router.currentRoute.params.id;
      axios
        .get(`/security_analysis/${securityAnalysisId}`)
        .then((response) => {
          loading.value = false;
          scoutsuiteResult.value = response.data.result;
          sessionStorage.setItem(
            "scoutsuiteResult",
            JSON.stringify(scoutsuiteResult.value)
          );
          for (const [key, value] of Object.entries(
            scoutsuiteResult.value.last_run.summary
          )) {
            const objFinding = Object.keys(
              scoutsuiteResult.value?.services[key]?.findings
            )[0];
            if (value.flagged_items !== 0 && value.checked_items !== 0) {
              tableItems.value.push({
                title: objFinding
                  ? scoutsuiteResult.value.services[key].findings[objFinding]
                      .service
                  : key,
                services: key,
                resources: value.resources_count,
                rules: value.rules_count,
                findings: value.flagged_items,
                checks: value.checked_items,
                max_level: value.max_level,
              });
            }
          }
        })
        .catch((err) => {
          loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: err.response.data.error.message,
            },
          });
        });
    };

    return {
      getTableData,
      loading,
      tableItems,
      scoutsuiteResult,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.open-ticket {
  border: 1px solid $partner-border-color;
  border-radius: 0.375rem;
}
.action-class {
  width: 15%;
}
.feather {
  margin-right: 8px;
}
.card-height {
  min-height: 400px;
  max-height: 100%;
}
</style>
